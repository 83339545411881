import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPag from "../scenes/Login/LoginPag";
import LoginHelp from "../scenes/Login/LoginHelp";
import ForgotPassword from "../scenes/Login/ForgotPassword";
import Protected from "../Data/Protected";

import Topbar from "../scenes/global/Topbar";
import MainSidebar from "../scenes/global/MainSidebar";
import AdminDashboard from "../scenes/Dashboard/AdminDash/AdminDashboard";
import Sales from "../scenes/Leads/Sales";
import Leads from "../scenes/Leads/Leads";
import MainCompanySettings from "../scenes/Settings/MainCompanySettings";
import MainBusiness from "../scenes/Settings/MainBusiness";
import MainProfileSettings from "../scenes/Settings/MainProfileSettings";
import DriverUser from "../scenes/Users/Driver User/DriverUser";
import FleetOwnerUser from "../scenes/Users/Fleet Owner User/FleetOwnerUser";

import Employee from "../scenes/HR/Employee/Employee";
import MainTaxSettings from "../scenes/Settings/MainTaxSettings";
import MainProjectSettings from "../scenes/Settings/MainProjectSettings";
import MainRolesSettings from "../scenes/Settings/MainRolesSettings";
import MainTicketSettings from "../scenes/Settings/MainTicketSettings";
import MainAttendanceSettings from "../scenes/Settings/MainAttendanceSettings";
import MainLeadsSettings from "../scenes/Settings/MainLeadsSettings";
import MainPayrollSettings from "../scenes/Settings/MainPayrollSettings";
import MainAssetsSettings from "../scenes/Settings/MainAssetsSettings";
import MainRecruitSettings from "../scenes/Settings/MainRecruitSettings";
import MainPurchaseSettings from "../scenes/Settings/MainPurchaseSettings";
import MainSecuritySettings from "../scenes/Settings/MainSecuritySettings";
import MainTaskSettings from "../scenes/Settings/MainTaskSettings";
import MainTImeLogSetttings from "../scenes/Settings/MainTImeLogSetttings";
import Dhaba from "../scenes/Users/Dhaba/Dhaba";
import Mechanic from "../scenes/Users/Mechanic/Mechanic";
import CraneOwner from "../scenes/Users/CraneOwner/CraneOwner";
import Jobs from "../scenes/Jobs/Jobs";
import Application from "../scenes/Jobs/Application";
// import PayrollReport from './scenes/Payroll/PayrollReport/PayrollReport';
import StaffSalary from "../scenes/Payroll/SaffSalary/StaffSalary";
import Asset from "../scenes/Assets/Asset/Asset";
import Bills from "../scenes/Assets/Bills/Bills";
import Products from "../scenes/Assets/Products/Products";
import PurOrder from "../scenes/Assets/PurOrder/PurOrder";
import Vendor from "../scenes/Assets/Vendor/Vendor";
import Orders from "../scenes/Assets/Orders/Orders";
import VendorPay from "../scenes/Assets/VendorPay/VendorPay";
import VendorCredits from "../scenes/Assets/VendorCredits/VendorCredits";
import Invento from "../scenes/Assets/Inventory/Inventory";
import Proposal from "../scenes/Finance/Proposal/Proposal";
import Estimates from "../scenes/Finance/Estimates/Estimates";
import Invoices from "../scenes/Finance/Invoices/Invoices";
import Payments from "../scenes/Finance/Payments/Payments";
import CreditNote from "../scenes/Finance/CreditNote/CreditNote";
import Expenses from "../scenes/Finance/Expenses/Expenses";
import BankAcoount from "../scenes/Finance/BankAccount/BankAccount";
import Settlement from "../scenes/Finance/Settlement/Settlement";
import Wallet from "../scenes/Finance/Wallet/Wallet";
import Contract from "../scenes/Work/Contract/Contract";

import Generate from "../scenes/Letter/Generate/Generate";
import Template from "../scenes/Letter/Template/Template";
import Skills from "../scenes/Recruit/Skills/Skills";
import RecJobs from "../scenes/Recruit/RecJobs/RecJobs";
import RecApp from "../scenes/Recruit/RecApp/RecApp";
import OffLetter from "../scenes/Recruit/OffLetter/OffLetter";
import Jitsi from "../scenes/Meetings/Jitsi/Jitsi";
import Teams from "../scenes/Meetings/Teams/Teams";
import Zoom from "../scenes/Meetings/Zoom/Zoom";
import Department from "../scenes/HR/Department/Department";
import Designation from "../scenes/HR/Designation/Designation";
import NoticeBoard from "../scenes/NoticeBoard/NoticeBoard";
import KnowledgeBase from "../scenes/KnowledgeBase/KnowledgeBase";
import AssetReport from "../scenes/Assets/Report/AssetReport";
import TaskReport from "../scenes/MainReport/TaskReport/TaskReport";
import TimelogReport from "../scenes/MainReport/TimelogReport/TimelogReport";
import FinanceReport from "../scenes/MainReport/FinanceReport/FinanceReport";
import IncVsExp from "../scenes/MainReport/IncVsExp/IncVsExp";
import LeaveReport from "../scenes/MainReport/LeaveReport/LeaveReport";
import DealReport from "../scenes/MainReport/DealReport/DealReport";
import SalesReport from "../scenes/MainReport/SalesReport/SalesReport";

import CraneOperator from "../scenes/Users/CraneOperator/CraneOperator";
import Leaves from "../scenes/HR/Leaves/Leaves";
import Appreciation from "../scenes/HR/Appreciation/Appreciation";
import Tickets from "../scenes/Tickets/Tickets";

import Attendance from "../scenes/HR/Attendance/Attendance";
import CanDb from "../scenes/Recruit/CanDb/CanDb";
import Reports from "../scenes/Recruit/Reports/Reports";
import InterSchedule from "../scenes/Recruit/InterSchedule/InterSchedule";
import ShiftRoaster from "../scenes/HR/ShiftRoaster/ShiftRoaster";
import Holiday from "../scenes/HR/Holiday/Holiday";
import RecruitDash from "../scenes/Recruit/RecruitDash/RecruitDash";
import DashboardTopBar from "../scenes/Dashboard/DashboardTopBar";
import OnFieldTeam from "../scenes/OnFieldTeam/OnFieldTeam";
import MainVehiclebrand from "../scenes/Vehicelbrand/MainVehiclebrand";

import CraneBooking from "../scenes/CraneBooking/CraneBooking";
import AppPosts from "../scenes/AppPosts/AppPosts";
import { useNavigate } from "react-router-dom";
import Companies from "../scenes/Companies/Companies";
import PayrollReport from "../scenes/Payroll/PayrollReport/PayrollReport";
import EmployeeView from "../scenes/HR/Employee/EmployeeView/EmployeeView";

import NotFound from "../scenes/global/NotFound.jsx";
import MainSettings from "../scenes/Settings/MainSettings.jsx";
import MainViewMore from "../scenes/Leads/ViewMore/MainViewMore.jsx";
import SaleNote from "../scenes/Leads/SalesNotes/SaleNote.jsx";
import ViewProfile from "../ProfileLayout/ViewProfile.jsx";
import Employeeview from "../scenes/HR/Employee/EmployeeView/EmployeeView";
import Project from "../scenes/Work/Projects/Project.jsx";

import TimeSheetView from "../scenes/Work/TimeSheet/TimeSheetView.jsx";
import ContractView from "../scenes/Work/Contract/ContractView/ContractView.jsx";
import Convoy from "../scenes/Convoy/Convoy.jsx";
import MainViewConvoy from "../scenes/Convoy/ViewMore/MainViewConvoy.jsx";
import MainViewJobs from "../scenes/Jobs/ViewMore/MainViewJobs.jsx";
import Events from "../scenes/Events/events.jsx";
import MainReportAsset from "../scenes/Assets/Report/MainReportAsset.jsx";
import AttendanceReport from "../scenes/MainReport/AttendanceReport/AttendanceReport.jsx";
import ViewMoreTickets from "../scenes/Tickets/ViewMoreTickets/ViewMoreTickets.jsx";
import ViewMoreAssets from "../scenes/Assets/Asset/ViewMoreAssets.jsx";
import ViewMoreJobs from "../scenes/Recruit/RecJobs/ViewMoreJobs.jsx";
import ViewMoreLeaves from "../scenes/HR/Leaves/ViewMoreLeaves.jsx";
import ViewMoreNotice from "../scenes/NoticeBoard/ViewMoreNotice.jsx";
import EditNotice from "../scenes/NoticeBoard/EditNotice.jsx";

import Message from "../scenes/Message/Message.jsx";
import Tasks from "../scenes/Work/Tasks/Tasks.jsx";
import AddNotice from "../scenes/NoticeBoard/AddNotice.jsx";
import ViewMoreKnowledge from "../scenes/KnowledgeBase/ViewMoreKnowledge.jsx";
import Clients from "../scenes/Clients/Clients.jsx";
import Membership from "../scenes/Membership/Membership.jsx";
import ViewMoreClients from "../scenes/Clients/ViewMoreClients.jsx/ViewMoreClients.jsx";
import MainFinanceSettings from "../scenes/Settings/MainFinanceSettings.jsx";
import DriverAttendance from "../scenes/Clients/ViewMoreClients.jsx/DriverAttendance.jsx";
import DriverClients from "../scenes/Clients/ViewMoreClients.jsx/DriverClients.jsx";

const Allroutes = () => {
  return (
    <Routes>
      {/* Auth routes  */}
      <Route path="/login" exact element={<LoginPag />} />
      <Route path="/login-help" exact element={<LoginHelp />} />
      <Route path="/reset-password" exact element={<ForgotPassword />} />
      {/* Protected routes */}
      <Route path="/rptpl" element={<Protected cmp={DashboardTopBar} />} />
      <Route
        path="/admin-dashboard"
        element={<Protected cmp={AdminDashboard} />}
      />

      <Route path="/field_team" element={<Protected cmp={OnFieldTeam} />} />
      <Route path="/leads/sales" element={<Protected cmp={Sales} />} />
      <Route
        path="/leads/sales/:id"
        element={<Protected cmp={ViewProfile} />}
      />
      <Route path="/leads" element={<Protected cmp={Leads} />} />
      <Route path="/leads/:id" element={<Protected cmp={MainViewMore} />} />
      <Route path="/companies" element={<Protected cmp={Companies} />} />
      <Route
        path="/Users/Crane_Owners"
        element={<Protected cmp={CraneOwner} />}
      />
      <Route
        path="/Users/Crane_Ope"
        element={<Protected cmp={CraneOperator} />}
      />
      <Route path="/Users/mech_user" element={<Protected cmp={Mechanic} />} />
      <Route
        path="/Users/fleet_Owners"
        element={<Protected cmp={FleetOwnerUser} />}
      />
      <Route
        path="/Users/fleet_Owners/:id"
        element={<Protected cmp={ViewProfile} />}
      />
      <Route path="/Users/dhaba" element={<Protected cmp={Dhaba} />} />
      <Route path="/Convoy" element={<Protected cmp={Convoy} />} />
      <Route path="/Convoy/:id" element={<Protected cmp={MainViewConvoy} />} />
      <Route path="/Crane_booking" element={<Protected cmp={CraneBooking} />} />
      <Route path="/Jobs" element={<Protected cmp={Jobs} />} />
      <Route path="/Jobs/:id" element={<Protected cmp={MainViewJobs} />} />

      <Route path="/payroll/staff" element={<Protected cmp={StaffSalary} />} />
      <Route
        path="/payroll/payroll"
        element={<Protected cmp={PayrollReport} />}
      />
      <Route path="/Users/Drivers" element={<Protected cmp={DriverUser} />} />

      <Route
        path="/Users/Drivers/:id"
        element={<Protected cmp={ViewProfile} />}
      />
      <Route path="/App_Posts" element={<Protected cmp={AppPosts} />} />
      <Route path="/assets" element={<Protected cmp={Asset} />} />
      <Route path="/assets/:id" element={<Protected cmp={ViewMoreAssets} />} />
      <Route path="/assets/bills" element={<Protected cmp={Bills} />} />
      <Route path="/assets/products" element={<Protected cmp={Products} />} />
      <Route
        path="/assets/purchase_order"
        element={<Protected cmp={PurOrder} />}
      />
      <Route path="/assets/vendor" element={<Protected cmp={Vendor} />} />
      <Route path="/assets/orders" element={<Protected cmp={Orders} />} />
      <Route
        path="/assets/vendor_payments"
        element={<Protected cmp={VendorPay} />}
      />
      <Route
        path="/assets/vendor_credits"
        element={<Protected cmp={VendorCredits} />}
      />
      <Route path="/assets/Inventory" element={<Protected cmp={Invento} />} />
      <Route
        path="/assets/report"
        element={<Protected cmp={MainReportAsset} />}
      />
      <Route path="/work/contracts" element={<Protected cmp={Contract} />} />
      <Route
        path="/work/contracts/:id"
        element={<Protected cmp={ContractView} />}
      />
      <Route path="/work/projects" element={<Protected cmp={Project} />} />
      <Route path="/work/tasks" element={<Protected cmp={Tasks} />} />
      <Route
        path="/work/time_Sheet"
        element={<Protected cmp={TimeSheetView} />}
      />
      <Route path="/finance/proposal" element={<Protected cmp={Proposal} />} />
      <Route path="/finance/estimate" element={<Protected cmp={Estimates} />} />
      <Route path="/finance/invoice" element={<Protected cmp={Invoices} />} />
      <Route path="/finance/payments" element={<Protected cmp={Payments} />} />
      <Route path="/finance/credit" element={<Protected cmp={CreditNote} />} />
      <Route path="/finance/expenses" element={<Protected cmp={Expenses} />} />
      <Route path="/finance/bank" element={<Protected cmp={BankAcoount} />} />
      <Route
        path="/finance/settlements"
        element={<Protected cmp={Settlement} />}
      />
      <Route path="/finance/Wallet" element={<Protected cmp={Wallet} />} />
      <Route path="/letter/generate" element={<Protected cmp={Generate} />} />
      <Route path="/letter/templete" element={<Protected cmp={Template} />} />
      <Route path="/recruit/skills" element={<Protected cmp={Skills} />} />
      <Route path="/recruit/jobs" element={<Protected cmp={RecJobs} />} />
      <Route
        path="/recruit/jobs/:id"
        element={<Protected cmp={ViewMoreJobs} />}
      />
      <Route
        path="/recruit/applications"
        element={<Protected cmp={RecApp} />}
      />
      <Route path="/recruit/offer" element={<Protected cmp={OffLetter} />} />
      <Route path="/recruit/reports" element={<Protected cmp={Reports} />} />
      <Route
        path="/recruit/interview"
        element={<Protected cmp={InterSchedule} />}
      />
      <Route
        path="/recruit/dashboard"
        element={<Protected cmp={RecruitDash} />}
      />
      <Route path="/recruit/candDb" element={<Protected cmp={CanDb} />} />
      <Route path="/hr/department" element={<Protected cmp={Department} />} />
      <Route path="/hr/shift" element={<Protected cmp={ShiftRoaster} />} />
      <Route path="/hr/holiday" element={<Protected cmp={Holiday} />} />
      <Route path="/hr/designation" element={<Protected cmp={Designation} />} />
      <Route
        path="/hr/employees"
        element={<Protected cmp={Employee} employee={""} />}
      />
      <Route
        path="/hr/employees/:id"
        element={<Protected cmp={Employeeview} />}
      />

      <Route path="/hr/leaves" element={<Protected cmp={Leaves} />} />
      <Route
        path="/hr/leaves/:id"
        element={<Protected cmp={ViewMoreLeaves} />}
      />
      <Route
        path="/hr/appreciation"
        element={<Protected cmp={Appreciation} />}
      />
      <Route path="/hr/attendance" element={<Protected cmp={Attendance} />} />
      <Route path="/tickets" element={<Protected cmp={Tickets} />} />
      <Route
        path="/tickets/:id"
        element={<Protected cmp={ViewMoreTickets} />}
      />
      <Route path="/meetings/jitsi" element={<Protected cmp={Jitsi} />} />
      <Route path="/meetings/teams" element={<Protected cmp={Teams} />} />
      <Route path="/meetings/zoom" element={<Protected cmp={Zoom} />} />
      <Route path="/notice" element={<Protected cmp={NoticeBoard} />} />
      <Route path="/notice/create" element={<Protected cmp={AddNotice} />} />
      <Route path="/notice/:id" element={<Protected cmp={ViewMoreNotice} />} />
      <Route path="/notice/:id/edit" element={<Protected cmp={EditNotice} />} />
      <Route path="/events" element={<Protected cmp={Events} />} />
      <Route path="/knowledge" element={<Protected cmp={KnowledgeBase} />} />
      <Route
        path="/knowledge/:id"
        element={<Protected cmp={ViewMoreKnowledge} />}
      />
      <Route path="/report/task" element={<Protected cmp={TaskReport} />} />
      <Route
        path="/report/time_log"
        element={<Protected cmp={TimelogReport} />}
      />
      <Route
        path="/report/finance"
        element={<Protected cmp={FinanceReport} />}
      />
      <Route path="/report/income" element={<Protected cmp={IncVsExp} />} />
      <Route path="/report/leave" element={<Protected cmp={LeaveReport} />} />
      <Route path="/report/deal" element={<Protected cmp={DealReport} />} />
      <Route path="/report/sales" element={<Protected cmp={SalesReport} />} />
      <Route
        path="/report/attendance"
        element={<Protected cmp={AttendanceReport} />}
      />
      <Route path="/help" element={<Protected cmp={LoginHelp} />} />
      <Route path="/message" element={<Protected cmp={Message} />} />
      <Route path="/App_settings" element={<Protected cmp={MainSettings} />} />
      <Route
        path="/settings"
        element={<Protected cmp={MainCompanySettings} />}
      />
      <Route
        path="/Business_settings"
        element={<Protected cmp={MainBusiness} />}
      />
      <Route
        path="/Vehicals_Data"
        element={<Protected cmp={MainVehiclebrand} />}
      />
      <Route
        path="/Profile_settings"
        element={<Protected cmp={MainProfileSettings} />}
      />
      <Route
        path="/tax_settings"
        element={<Protected cmp={MainTaxSettings} />}
      />
      <Route
        path="/project_settings"
        element={<Protected cmp={MainProjectSettings} />}
      />
      <Route
        path="/rp_settings"
        element={<Protected cmp={MainRolesSettings} />}
      />
      <Route
        path="/Ticket_settings"
        element={<Protected cmp={MainTicketSettings} />}
      />
      <Route
        path="/Attendance_settings"
        element={<Protected cmp={MainAttendanceSettings} />}
      />
      <Route
        path="/Leads_settings"
        element={<Protected cmp={MainLeadsSettings} />}
      />
      <Route
        path="/Payroll_settings"
        element={<Protected cmp={MainPayrollSettings} />}
      />
      <Route
        path="/Leave_settings"
        element={<Protected cmp={MainLeadsSettings} />}
      />
      <Route
        path="/recruit_settings"
        element={<Protected cmp={MainRecruitSettings} />}
      />
      <Route
        path="/assets_settings"
        element={<Protected cmp={MainAssetsSettings} />}
      />
      <Route
        path="/purchase_settings"
        element={<Protected cmp={MainPurchaseSettings} />}
      />
      <Route
        path="/security_settings"
        element={<Protected cmp={MainSecuritySettings} />}
      />
      <Route
        path="/Task_settings"
        element={<Protected cmp={MainTaskSettings} />}
      />

      <Route
        path="/finance_settings"
        element={<Protected cmp={MainFinanceSettings} />}
      />
      <Route
        path="/time_log_settings"
        element={<Protected cmp={MainTImeLogSetttings} />}
      />
      <Route path="/clients" element={<Protected cmp={Clients} />} />
      <Route
        path="/clients/:id"
        element={<Protected cmp={ViewMoreClients} />}
      />
      <Route
        path="/clients/driver/:id"
        element={<Protected cmp={ViewProfile} />}
      />

      <Route
        path="/clients/driver/:id/attendance"
        element={<Protected cmp={DriverAttendance} />}
      />
      <Route path="/membership" element={<Protected cmp={Membership} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Allroutes;
