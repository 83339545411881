import React, { useEffect, useMemo, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import StatusAlert from "../../../StatusAlertList/StatusAlert";

import { useLocation } from "react-router-dom";
import config from "../../../Data/AuthUser";

function DriverAttendance() {
  const [selectedDate, setSelectedDate] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const location = useLocation();
  const attendanceData = location.state;

  const handleClose = () => {
    setOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Create a date object from the input string

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date"; // Return an error message if the date is invalid
    }

    // Format the start date as desired (e.g., DD-MM-YYYY HH:MM AM/PM)
    return date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };
  const transformedEvents = useMemo(() => {
    return attendanceData.map((data) => {
      return {
        title: `${data.address} | ${formatDate(data.date)}`,
        start: data.date,
        id: data.id,
        // Display address and formatted start date
        extendedProps: {
          lat: data.lat,
          lng: data.lng,
          status: data.status,
          pic: data.pic,
          address: data.address,
          client: data.client_id,
          driver: data.driver_id,
        },
      };
    });
  }, [attendanceData]);

  const [currentEvents, setCurrentEvents] = useState([]);
  useEffect(
    (events) => {
      // Set events only if needed, to avoid an infinite loop
      if (currentEvents !== events) {
        setCurrentEvents(events);
      }
    },
    [currentEvents]
  );

  const filteredData = attendanceData.filter((data) => {
    // Assuming `data.date` is comparable to the selected date
    return data.date.startsWith(selectedDate); // Filter by selected date
  });
  const handleEventClick = () => {
    if (filteredData && filteredData.length > 0) {
      setOpen(true);
    }
  };

  // Callback function to set the current events
  const handleEventsSet = (events) => {
    setCurrentEvents(events);
  };

  const driverImage = config.apiImageUrl;
  const AttendanceImages = ({ attendanceData }) => {
    const driverImage = config.apiImageUrl;

    return (
      <div>
        {attendanceData.map((data) => (
          <img key={data.id} src={`${driverImage}${data.pic}`} alt="driver" />
        ))}
      </div>
    );
  };
  const handleDateSelect = (selectInfo) => {
    setOpen(true);
    setSelectedDate(selectInfo.startStr); // Update state with selected date
  };

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <Box
        flex="1 1 100%"
        m="15px 10px 15px 5px"
        sx={{ background: "#fff", color: "#000" }}
      >
        <FullCalendar
          height="75vh"
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
          ]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
          }}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          select={handleDateSelect}
          eventClick={handleEventClick}
          eventsSet={handleEventsSet}
          events={transformedEvents} // Use the transformed event data
        />
      </Box>
      {/* <AttendanceImages attendanceData={filteredData} /> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ bgcolor: "#fff", color: "#000" }}
        >
          <strong>{"Driver Attendance Profile"}</strong>
        </DialogTitle>
        <DialogContent sx={{ bgcolor: "#fff", color: "#000" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ bgcolor: "#fff", color: "#000" }}
          >
            {filteredData.map((data) => {
              return (
                <div key={data.id}>
                  {/* Use a unique key for each item */}
                  <div>
                    <strong>Location:</strong> {data.address}
                  </div>
                  <div>
                    <strong>Time:</strong> {formatDate(data.date)}
                  </div>
                  <div>
                    <strong>Picture:</strong>{" "}
                    <img
                      key={data.id}
                      src={`${driverImage}${data.pic}`}
                      alt="driver"
                    />
                  </div>
                  <div>
                    <strong>Driver Id:</strong> {data.driver_id}
                  </div>
                  <div>
                    <strong>Client Id:</strong> {data.client_id}
                  </div>
                </div>
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#fff", color: "#000" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DriverAttendance;
