import {
  createTheme,
  ThemeProvider,
  Menu,
  MenuItem,
  Stack,
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Slide from "@mui/material/Slide";
import CoPresentOutlinedIcon from "@mui/icons-material/CoPresentOutlined";
import MUIDataTable from "mui-datatables";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";

import { useLocalStorageContext } from "../../../Context/LocalProvider";
import StatusAlert from "../../../StatusAlertList/StatusAlert";
import { Select } from "@mui/material";

import config from "../../../Data/AuthUser";
import { clientDriversApis, DriverApis } from "../../../Data/axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DriverClients() {
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [salary, setSalary] = useState("");
  const [allowance, setAllowance] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");
  const [driverData, setDriverData] = useState([]);
  const [name, setName] = useState("");
  const [openMenuId, setOpenMenuId] = useState(null);
  const [selectedRadio, setSelectedRadio] = useState("");
  const { data: userData } = useLocalStorageContext();
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const rowData = location.state;

  const { client, app_user } = rowData || {};

  const { user_id } = client || {};
  const { username } = app_user || {};

  const [date, setDate] = useState(null);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeDriver = (event) => {
    setName(event.target.value);
  };
  const userDataNew = localStorage.getItem("user-info");
  let creator;
  if (userDataNew) {
    // Parse the JSON string into an object
    const parsedData = JSON.parse(userDataNew);

    // Access the name property from the parsed object
    creator = parsedData.value.empid;
  } else {
    console.error("No user data found in localStorage.");
  }
  let valueToSend;

  const filteredDrivers = driverData.filter((driver) => {
    const searchLower = search.toLowerCase();
    return (
      (driver.username &&
        driver.username.toLowerCase().includes(searchLower)) ||
      (driver.phone && driver.phone.includes(search)) ||
      (driver.user_id && driver.user_id.toString().includes(search))
    );
  });
  const item = {
    client_id: user_id,
    salary: salary,
    allowance: allowance,
    join_date: date,
  };

  async function SaveData() {
    // Create a new FormData object
    const formData = new FormData();

    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }

    await clientDriversApis.SavebyId(name, formData);

    showAlert("Added successfully", "success");

    getData();
    setOpen(false);
    setName("");
    setSearch("");
  }

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeSelected = async (event, ticketId) => {
    const selectedValue = event.target.value;

    // Perform any state update or action based on selectedValue and ticketId
    const itemStatus = {
      status: selectedValue,
    };

    // Create a new FormData object
    const formData = new FormData();

    for (const key in itemStatus) {
      if (itemStatus[key] !== undefined && itemStatus[key] !== null) {
        formData.append(key, itemStatus[key]);
      }
    }

    await clientDriversApis.changeStatus(ticketId);

    showAlert("updated successfully", "success");

    getData();
  };

  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleDateChange = (newDate) => {
    setDate(newDate); // Update the date state
  };
  const handleViewMore = async () => {
    const rowData = await clientDriversApis.viewMoreDriver(openMenuId);

    navigate(`/clients/driver/${openMenuId}`, {
      state: rowData,
      clientId: user_id,
    });
  };
  const handleViewAttendence = async () => {
    const formData = new FormData();
    formData.append("client_id", user_id);

    const rowData = await clientDriversApis.viewMoreAttendance(
      openMenuId,
      formData
    );
    navigate(`/clients/driver/${openMenuId}/attendance`, { state: rowData });
  };

  const [leadFiles, setLeadFiles] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const convertToUserType = (value) => {
    if (value === "1") return "Enabled";
    if (value === "2") return "Disabled";
    return "Unknown"; // Fallback for invalid values
  };
  useEffect(() => {
    getDrivers();
  }, []);
  async function getDrivers() {
    const result = await DriverApis.getAll();
    setDriverData(result);
  }
  async function getData() {
    const result = await clientDriversApis.getAll(user_id);

    setLeadFiles(result.data);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //adding follow up

  const columns = [
    {
      name: "app_user",
      label: "Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Name
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0].user_id;

          const fullRecord = leadFiles.find(
            (item) => item.app_user.user_id === ticketId
          );

          if (!fullRecord) {
            return <div>Loading...</div>;
          }

          const imageUrl = `${config.apiImageUrl}${fullRecord.app_user.image}`;

          return (
            <div
              key={value}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={imageUrl}
                alt="requester"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "10px",
                }}
              />
              <div>
                <div style={{ fontWeight: "bold" }}>
                  {(value.username || "Unknown").charAt(0).toUpperCase() +
                    (value.username || "Unknown").slice(1)}
                </div>
                <div>{ticketId || "Unknown"}</div>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "corp_driver",
      label: "Start Date",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Start Date
            </th>
          );
        },
        customBodyRender: (value) => {
          const date = new Date(value.join_date);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          const formattedDate = `${day}-${month}-${year}`;
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {formattedDate || " "}
            </div>
          );
        },
      },
    },
    {
      name: "app_user",
      label: "Phone No.",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Phone No.
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value.phone || " "}
            </div>
          );
        },
      },
    },
    {
      name: "corp_driver",
      label: "Salary",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Salary
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value.salary || " "}
            </div>
          );
        },
      },
    },
    {
      name: "corp_driver",
      label: "Relieve",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Relieve
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value.releave_date || " "}
            </div>
          );
        },
      },
    },

    {
      name: "corp_driver",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0].user_id;

          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Select
                value={value.status}
                onChange={(event) => handleChangeSelected(event, ticketId)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "1") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Active
                      </MenuItem>
                    ); // Placeholder text
                  }
                  if (selected === "2") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Relieve
                      </MenuItem>
                    ); // Placeholder text
                  }
                  if (selected === "3") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Leave
                      </MenuItem>
                    ); // Placeholder text
                  }
                  return selected; // Show selected value
                }}
                sx={{
                  width: "110px",

                  border: "none",
                  color: "#000 ",
                  bgcolor: "#fff",
                  "& .MuiSelect-select": {
                    padding: 0,
                  },
                }}
              >
                <MenuItem
                  value={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Active
                </MenuItem>
                <MenuItem
                  value={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Relieve
                </MenuItem>
                <MenuItem
                  value={3}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Leave
                </MenuItem>
              </Select>
            </div>
          );
        },
      },
    },

    {
      name: "corp_driver",
      label: "Actions",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "right",
                paddingRight: "70px",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "20px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value.driver_id)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewMore()}
                      className="MenuItemValue"
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveRedEyeOutlinedIcon /> &nbsp; View More
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewAttendence()}
                      className="MenuItemValue"
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <CoPresentOutlinedIcon />
                      &nbsp; Attendence
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </>
        ),
      },
    },
  ];

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    elevation: 0,
    // rowsPerPage: 5,
    // rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {},
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  // Table Ends Here

  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />

      <List
        style={{
          background: "none",
          // padding: 5,
        }}
      >
        <React.Fragment>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              marginBottom: "5px",
              // marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              onClick={handleClickOpen}
              sx={{
                backgroundColor: "#1d82f5",
                "&:hover": { backgroundColor: "orange" },
                textAlign: "center",
                padding: "10px",
                marginLeft: "10px",
                height: "40px",
                width: "120px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                borderRadius: "4px",
                fontSize: "15px",
                fontFamily: "sans-serif",
                cursor: "pointer",
                //  marginTop: "20px",
              }}
            >
              <AddIcon />
              Add Driver
            </Box>
          </Stack>
          <Dialog
            fullWidth
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{
              style: {
                position: "absolute",
                background: "#141b2d",
                top: "0",
                backgroundColor: "white",
                maxHeight: "95%",
                margin: "auto",
                overflowX: "hidden",
                zIndex: 1200,
              },
            }}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} component="div">
                  Add New Driver
                </Typography>
              </Toolbar>
            </AppBar>
            <Box sx={{ background: "#fff" }}>
              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px", paddingLeft: "20px" }}
              >
                <Grid item xs={11} sm={5.5} md={5.5}>
                  <Stack direction="column">
                    <Typography>Name</Typography>

                    {/* Search Input Field */}
                    <TextField
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search by name, phone, or user ID"
                      variant="outlined"
                      fullWidth
                      inputProps={{ style: { color: "black" } }}
                      InputLabelProps={{ style: { color: "black" } }}
                      sx={{
                        border: "1px solid black",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black",
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          "&::placeholder": { color: "gray" },
                        },
                      }}
                    />

                    {/* Select Dropdown */}
                    <Select
                      value={name}
                      autoFocus
                      fullWidth
                      onChange={handleChangeDriver}
                      MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      sx={{
                        border: "1px solid black",
                        "& .MuiOutlinedInput-root": {
                          borderColor: "black !important",
                          "&:hover fieldset": {
                            borderColor: "black !important",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "black !important",
                          },
                        },
                        color: "black",
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>---</em>
                      </MenuItem>

                      {/* Mapping Filtered Drivers */}
                      {filteredDrivers.map((driver) => (
                        <MenuItem
                          value={driver.user_id}
                          key={driver.id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={`${config.apiImageUrl}${driver.image}`}
                            alt="requester"
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              objectFit: "cover",
                              marginRight: "10px",
                            }}
                          />
                          <div>
                            <div style={{ fontWeight: "bold" }}>
                              {driver.username || "Unknown"}
                            </div>
                            <div>{driver.user_id || "Unknown"}</div>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={11} sm={5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Client
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        value={username}
                        // onChange={(e) => setEmail(e.target.value)}
                        aria-readonly
                        fullWidth
                        placeholder="eg@example.com"
                        type="email"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px", paddingLeft: "20px" }}
              >
                <Grid item xs={11} sm={5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Salary
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={salary}
                        onChange={(e) => setSalary(e.target.value)}
                        placeholder="e.g.Rs 30,000"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={11} sm={5}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Allowance
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={allowance}
                        onChange={(e) => setAllowance(e.target.value)}
                        placeholder="e.g.Rs 500"
                        inputProps={{ style: { color: "black" } }}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{
                          border: "1px solid black",
                          borderRadius: "5px",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black",
                            "&:hover fieldset": {
                              borderColor: "black",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black",
                            },
                          },
                          "& .MuiOutlinedInput-input": {
                            "&::placeholder": { color: "gray" },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ color: "black", margin: "10px", paddingLeft: "20px" }}
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  sm={4}
                  sx={{ paddingRight: { xs: 0, sm: 2 } }}
                >
                  <Stack direction="column">
                    <Typography
                      variant="p"
                      gutterBottom
                      style={{ color: "black" }}
                    >
                      Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        onChange={(e) => handleDateChange(e.target.value)}
                        sx={{ marginTop: "-10px" }}
                      >
                        <DatePicker
                          value={date}
                          onChange={handleDateChange}
                          sx={{
                            border: "1px solid black",
                            borderRadius: "5px",
                            "& .MuiOutlinedInput-root": {
                              borderColor: "black !important",
                              color: "black !important",
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "black !important",
                              },
                            },
                            "& .MuiSvgIcon-root": {
                              color: "black",
                            },
                          }}
                        >
                          {(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        </DatePicker>
                      </DemoContainer>
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </Grid>

              <Grid container sx={{ padding: "10px 0 20px 38px" }}>
                <Grid item xs={11} className="messageFooter">
                  <Box className="response" onClick={SaveData}>
                    Save
                  </Box>
                  <Box className="cancel" onClick={handleClose}>
                    Cancel
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </React.Fragment>
        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <MUIDataTable
              title={
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "1.5rem",
                    color: "#333",
                  }}
                >
                  Driver Lists
                </span>
              }
              data={leadFiles}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </ListItemText>
      </List>
    </>
  );
}

export default DriverClients;
