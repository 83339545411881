import * as React from "react";

import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FlexLayout from "./Layout/FlexLayout";
import { Box, Button, Grid, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import DriverAttendance from "../scenes/Clients/ViewMoreClients.jsx/DriverAttendance";
export default function SelectOption({ rowData }) {
  const [selection, setSelection] = React.useState("");
  const navigate = useNavigate();
  const pageControl = () => {
    if (selection === "") {
      return <FlexLayout rowData={rowData} />;
    } else if (selection === 1) {
      return <div>History</div>;
    } else if (selection === 2) {
      return <div>Timeline</div>;
    } else if (selection === 3) {
      return <div>Insurance</div>;
    } else if (selection === 4) {
      return <div>Data</div>;
    } else if (selection === 5) {
      // return <DriverAttendance rowData={rowData} />;
    } else if (selection === 6) {
      return <div>Salary</div>;
    } else if (selection === 7) {
      return <div>Others</div>;
    }
  };
  const handleChange = (event) => {
    setSelection(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Select
            value={selection}
            onChange={handleChange}
            displayEmpty
            sx={{
              padding: "10px",
              width: "100px",
              margin: ".4rem",
              border: "1px solid gray",
              color: "#fff",
              bgcolor: "gray",
              "& .MuiSelect-select": {
                padding: 0,
              },
            }}
          >
            <MenuItem value="">View</MenuItem>
            <MenuItem value={1}>History</MenuItem>
            <MenuItem value={2}>Timeline</MenuItem>
            <MenuItem value={3}>Insurance</MenuItem>
            <MenuItem value={4}>Data</MenuItem>
            <MenuItem value={5}>Attendance</MenuItem>
            <MenuItem value={6}>Salary</MenuItem>
            <MenuItem value={7}>Others</MenuItem>
          </Select>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: ".4rem",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <input
                  type="text"
                  name="Bonus"
                  id="Bonus"
                  placeholder="Append, take off bonus"
                  style={{ width: "180px", height: "44px" }}
                />
              </Grid>
            </Grid>

            <Button
              variant="outlined"
              sx={{
                color: "#000", // Text color
                borderColor: "black", // Border color
                width: "180px",
                padding: "10px",
                backgroundColor: "lightgreen", // Button width
                "&:hover": {
                  borderColor: "black", // Border color on hover
                  backgroundColor: "rgba(0, 0, 0, 0.1)", // Optional: change background color on hover
                },
                "& .MuiButton-startIcon": {
                  color: "black", // Icon color
                },
              }}
            >
              <AddIcon /> Add Ticket
            </Button>
          </Box>
        </Box>
        <br />
        <Box sx={{ width: "100%" }}>{pageControl()}</Box>
      </FormControl>
    </div>
  );
}
