import {
  Box,
  Grid,
  createTheme,
  ThemeProvider,
  Menu,
  Stack,
  Select,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogTitle,
  DialogActions,
  FormLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";

import RememberMeIcon from "@mui/icons-material/RememberMe";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import AddIcon from "@mui/icons-material/Add";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditNoteIcon from "@mui/icons-material/EditNote";

import { useNavigate } from "react-router-dom";

import StatusAlert from "../../StatusAlertList/StatusAlert";
import ReactQuill from "react-quill";
import ClientsHeader from "./ClientsHeader";
import { clientApis } from "../../Data/axios";
import config from "../../Data/AuthUser";
import { getUserInfo } from "../../Data/UserData";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Clients() {
  const [name, setName] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [radioButton, setRadioButton] = useState(false);
  const [industryName, setIndustryName] = React.useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [companyName, setCompanyName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [alertSeverity, setAlertSeverity] = useState("info");

  const [website, setWebsite] = React.useState("");
  const [taxName, setTaxName] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [state, setState] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [billing, setBilling] = React.useState("");
  const [industryType, setIndustyType] = React.useState("");
  const [shipping, setShipping] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [companyEmail, setCompanyEmail] = React.useState("");
  const [isLogin, setIsLogin] = useState("1");
  const [emailNot, setEmailNot] = useState("1");
  const [industryTypeData, setIndustryTypeData] = useState([]);
  const [openDialogDesig, setOpenDialogDesig] = useState(false);

  const [articalData, setArticalData] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("Client");
  const [data, setData] = useState([]);
  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };
  useEffect(() => {
    if (selectedRadio === "App User") {
      setRadioButton(true);
    } else {
      setRadioButton(false);
    }
  }, [selectedRadio]);
  const handleAddClickDesig = () => {
    setOpenDialogDesig(true); // Open dialog when Add button is clicked
  };
  useEffect(() => {
    fetchIndustryData();
  }, []);
  const fetchIndustryData = async () => {
    try {
      const data = await clientApis.industryAll();

      setIndustryTypeData(data);
    } catch (err) {
      console.error("Failed to fetch data. Please try again later.");
    }
  };
  const handleDialogCloseDesig = () => {
    setOpenDialogDesig(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleFileChange = (event) => {
    setUploadFile(event.target.files[0]);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateEdit = () => {
    setOpenEdit(true);
  };

  const handleChangeSelected = async (event, ticketId) => {
    const selectedValue = event.target.value;

    // Perform any state update or action based on selectedValue and ticketId
    const itemStatus = {
      status: selectedValue,
    };

    // Create a new FormData object
    const formData = new FormData();

    for (const key in itemStatus) {
      if (itemStatus[key] !== undefined && itemStatus[key] !== null) {
        formData.append(key, itemStatus[key]);
      }
    }

    // await DriverApis.changeStatus(ticketId, formData);

    showAlert("updated successfully", "success");

    getData();
  };
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [description, setDescription] = useState("");

  const handleOpenMenu = (id) => {
    setOpenMenuId(id);
  };
  const handleViewMore = async () => {
    const rowData = await clientApis.clientViewMoreId(openMenuId);
    navigate(`/clients/${openMenuId}`, { state: rowData });

    // setOpenDialog(true);
  };

  // Function Starts Here

  const [updateName, setUpdateName] = React.useState("");
  const [updateRadio, setUpdateRadio] = React.useState("");

  const [updateDescription, setUpdateDescription] = React.useState("");
  const [updateCategory, setUpdateCategory] = useState(""); // Initialize state for the date
  const [updateType, setUpdateType] = useState("");

  const [articalCategory, setArticalCategory] = useState([]);

  const [openMenuId, setOpenMenuId] = useState(null);

  const userInfoString = localStorage.getItem("user-info");

  // Parse the string into an object
  const userInfo = JSON.parse(userInfoString);
  const handleAllData = () => {
    setOpen(false);
    setName("");
    setGender("");
    setDescription("");
    setUploadFile("");
    setSelectedRadio("");
    setSelectCategory("");
    setIndustyType("");
  };
  // Access the empid

  const newid = userInfo?.value?.empid;
  const item = {
    username: name,
    email: email,
    phone: mobile,
    address: billing,
    state: state,
    pincode: postalCode,
    company_email: companyEmail,
    company_phone: phoneNumber,
    company_website: website,
    gender: gender,
    ref: getUserInfo(),
    industry_type: "2",
    company_name: companyName,
    tax_name: taxName,
    tax_number: number,
    allow_login: isLogin,
    notification: emailNot,
    note: description,
  };

  async function SaveData() {
    const formData = new FormData();

    // Append each item to the FormData object
    for (const key in item) {
      if (item[key] !== undefined && item[key] !== null) {
        formData.append(key, item[key]);
      }
    }

    let result = await clientApis.add(formData);

    if (!result) {
      throw new Error(`HTTP error! status: ${result.status}`);
    }

    showAlert("Added successfully", "success");
    handleAllData();
    getData();
  }

  // ends Here

  // Table Starts Here
  const handleFollowUp = () => {};
  const handleNote = () => {};
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const response = await clientApis.getAll();

    setArticalData(response); // Store the updated response
  }

  const convertToUserType = (value) => {
    if (value === "1") return "Client";
    if (value === "2") return "Employee";
    if (value === "3") return "App User";
    return "Unknown"; // Fallback for invalid values
  };
  const convertToMainUserType = (value) => {
    if (value === "1") return "Driver";
    if (value === "2") return "Owner";
    if (value === "3") return "Dhaba";
    if (value === "4") return "Mechanic";
    if (value === "5") return "Crane Owner";
    if (value === "6") return "Crane Operator";

    return "Unknown"; // Fallback for invalid values
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      name: "user_id",
      label: "Client ID",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Client ID
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "app_user",
      label: "Name",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Name
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0];

          const fullRecord = articalData.find(
            (item) => item.user_id === ticketId
          );

          if (!fullRecord) {
            return <div>Loading...</div>;
          }

          const imageUrl = `${config.apiImageUrl}${fullRecord.app_user.image}`;

          return (
            <div
              key={value.id}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={imageUrl}
                alt="requester"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "10px",
                }}
              />
              <div>
                <div style={{ fontWeight: "bold" }}>
                  {value.username || "Unknown"}
                </div>
                <div style={{ fontWeight: "bold" }}>
                  {fullRecord.company_website || "Unknown"}
                </div>
              </div>
            </div>
          );
        },
      },
    },

    {
      name: "company_email",
      label: "Email",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Email
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {(value || " ").charAt(0).toUpperCase() + (value || " ").slice(1)}
            </div>
          );
        },
      },
    },
    {
      name: "company_phone",
      label: "Phone No",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Phone No
            </th>
          );
        },
        customBodyRender: (value) => {
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {value || " "}
            </div>
          );
        },
      },
    },
    {
      name: "created_at",
      label: " Time",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Time
            </th>
          );
        },
        customBodyRender: (value) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString("en-GB");
          const formattedDateWithDashes = formattedDate.replace(/\//g, "-");
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {formattedDateWithDashes || " "}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Status
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const ticketId = tableMeta.rowData[0];
          return (
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Select
                value={value}
                onChange={(event) => handleChangeSelected(event, ticketId)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === "1") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Enabled
                      </MenuItem>
                    ); // Placeholder text
                  }
                  if (selected === "2") {
                    return (
                      <MenuItem sx={{ display: "flex", alignItems: "center" }}>
                        Disabled
                      </MenuItem>
                    ); // Placeholder text
                  }
                  return selected; // Show selected value
                }}
                sx={{
                  width: "110px",

                  border: "none",
                  color: "#000 ",
                  bgcolor: "#fff",
                  "& .MuiSelect-select": {
                    padding: 0,
                  },
                }}
              >
                <MenuItem
                  value={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Enabled
                </MenuItem>
                <MenuItem
                  value={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  Disabled
                </MenuItem>
              </Select>
            </div>
          );
        },
      },
    },
    {
      name: "user_id",
      label: "Action",
      options: {
        customHeadRender: ({ index }) => {
          return (
            <th
              key={index}
              style={{
                textAlign: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              Action
            </th>
          );
        },
        customBodyRender: (value, tableMeta) => (
          <>
            <div
              key={value}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns content to the left
                alignItems: "center",
                paddingRight: "20px", // Add padding for starting point
              }}
            >
              <div style={{ paddingRight: "20px" }}>
                <Button
                  id="basic-button"
                  aria-controls={openDropMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropMenu ? "true" : undefined}
                  onClick={handleClick}
                >
                  <div onClick={() => handleOpenMenu(value)}>
                    <MoreHorizTwoToneIcon />
                  </div>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openDropMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleViewMore(tableMeta.rowData)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveRedEyeIcon /> View More
                    </div>
                  </MenuItem>

                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleFollowUp(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <RememberMeIcon /> Follow Up
                    </div>
                  </MenuItem>
                  <MenuItem onClick={handleCloseMenu}>
                    <div
                      onClick={() => handleNote(value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-center",
                        alignItems: "center",
                      }}
                    >
                      <EventNoteIcon /> Add Note
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </>
        ),
      },
    },
  ];

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };
  async function AddIndustry() {
    const formData = new FormData();

    formData.append("name", industryName);
    formData.append("addby_by", getUserInfo());

    let result = await clientApis.addIndustry(formData);
    if (result) {
      showAlert("Added successfully", "success");

      setOpenCategory(false);
      fetchIndustryData();
      setCategoryName("");
    }
  }

  const handleChangeLogin = (event) => {
    setIsLogin(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmailNot(event.target.value);
  };

  // Update Values Start

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setUpdateName("");
    setUpdateRadio("");
    setUpdateDescription("");
    setUploadFile("");
    setUpdateCategory("");
  };

  useEffect(() => {
    setUpdateName(data.title);
  }, [data.title]);

  useEffect(() => {
    setUpdateRadio(data.for);
  }, [data.for]);

  useEffect(() => {
    setUpdateDescription(data.description);
  }, [data.description]);

  useEffect(() => {
    setUploadFile(data.file);
  }, [data.file]);
  useEffect(() => {
    setUpdateCategory(data.article);
  }, [data.article]);
  useEffect(() => {
    setUpdateCategory(data.user_type);
  }, [data.user_type]);

  // Editing Ends Here

  // Delete

  const options = {
    selectableRows: "none",
    selectToolbarPlacement: "none",
    responsive: "standard",
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25, 100],
    tableBody: {
      style: {
        maxHeight: "500px",
        overflowY: "scroll",
        marginLeft: "5PX",
      },
    },
    tableHead: {
      style: {
        backgroundColor: "#f2f2f2",
        color: "black",
      },
    },
    rows: {
      style: {
        marginLeft: "5px",
        textAlign: "center",
      },
      selectableRowsOnClick: true,
    },
  };

  const getTheme = () =>
    createTheme({
      palette: {
        mode: "light",
        primary: {
          main: "#2196f3",
        },
        secondary: {
          main: "#03a9f4",
        },
      },
      typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "Sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },
      },
      tableBody: {
        style: {
          maxHeight: "500px",
          overflowY: "scroll",
        },
      },
      tableHead: {
        style: {
          backgroundColor: "#f2f2f2",
          color: "black",
        },
      },
      rows: {
        style: {
          "&:hover": {
            backgroundColor: "#f2f2f2",
          },
        },
      },
    });

  // Table Ends Here

  const GenderValue = [
    { id: "1", gender: "Male" },
    { id: "2", gender: "Female" },
    { id: "3", gender: "Others" },
  ];
  return (
    <>
      <StatusAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        severity={alertSeverity}
      />
      <ClientsHeader />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={openEdit}
        onClose={handleCloseEdit}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: "absolute",
            background: "#141b2d",
            top: "0",
            backgroundColor: "white",
            maxHeight: "95%",
            margin: "auto",
            overflowX: "hidden",
            zIndex: 1200,
          },
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseEdit}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} component="div">
              Update Article
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ background: "#fff" }}>
          <Grid
            container
            spacing={2}
            style={{ color: "black", margin: "10px" }}
          >
            <Grid item xs={11} sm={5} md={5}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={updateRadio}
                  onChange={(e) => setUpdateRadio(e.target.value)}
                >
                  <FormControlLabel
                    value="Employee"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "#1d82fs" },
                        }}
                      />
                    }
                    label="For Employees"
                  />
                  <FormControlLabel
                    value="Client"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "#1d82fs" },
                        }}
                      />
                    }
                    label="For Clients"
                  />
                  <FormControlLabel
                    value="App User"
                    control={
                      <Radio
                        sx={{
                          color: "black",
                          "&.Mui-checked": { color: "#1d82f5" },
                        }}
                      />
                    }
                    label="For App Users"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {radioButton && (
              <Grid item xs={11} sm={5} md={5}>
                <Typography sx={{ color: "#000", paddingBottom: "7px" }}>
                  UserType
                </Typography>
                <Select
                  value={updateType}
                  autoFocus
                  fullWidth
                  onChange={(e) => setUpdateType(e.target.value)}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  <MenuItem value={1}>Driver</MenuItem>
                  <MenuItem value={2}>Owner</MenuItem>
                  <MenuItem value={3}>Dhaba</MenuItem>
                  <MenuItem value={4}>Mechanic</MenuItem>
                  <MenuItem value={5}>Crane Owner</MenuItem>
                  <MenuItem value={6}>Crane Operator</MenuItem>
                </Select>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ color: "black", margin: "10px" }}
          >
            <Grid item xs={10.5} sm={5.5} md={5.5}>
              <Stack direction="column" spacing={2}>
                <Typography>Article Heading </Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  placeholder="Article Heading Name"
                  type="text"
                  fullWidth
                  value={updateName}
                  onChange={(e) => setUpdateName(e.target.value)}
                  inputProps={{ style: { color: "black" } }}
                  InputLabelProps={{
                    style: { color: "black" },
                  }}
                  sx={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black",
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      "&::placeholder": { color: "gray" },
                    },
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={10.5} sm={5.5} md={5.5}>
              <Stack direction="column" spacing={2}>
                <Typography>Article Category</Typography>
                <Select
                  value={updateCategory}
                  autoFocus
                  fullWidth
                  onChange={(e) => setUpdateCategory(e.target.value)}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  sx={{
                    border: "1px solid black",
                    boxSizing: "border-box",
                    "& .MuiOutlinedInput-root": {
                      borderColor: "black !important",
                      "&:hover fieldset": {
                        borderColor: "black !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black !important",
                      },
                    },
                    color: "black",
                  }}
                >
                  <MenuItem disabled value="">
                    <em>---</em>
                  </MenuItem>
                  {articalCategory.map((artical) => (
                    <MenuItem value={artical.id} key={artical.id}>
                      {artical.title}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
          </Grid>

          <Grid container sx={{ padding: "20px", marginBottom: "10px" }}>
            <Stack direction="column" sx={{ width: "100%" }}>
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                Description<span style={{ color: "red" }}>*</span>
              </Typography>

              <ReactQuill
                value={updateDescription}
                onChange={setUpdateDescription}
                modules={modules}
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  color: "black",
                  borderBlockColor: "black",
                }}
              />
            </Stack>
          </Grid>
          <Grid
            container
            sx={{
              padding: {
                xs: "60px 10px 20px 20px",
                sm: "40px 10px 20px 20px",
                md: "20px 10px 20px 20px",
              },
            }}
          >
            <Stack direction="column" sx={{ width: "100%" }}>
              <br></br>
              <Typography variant="p" gutterBottom style={{ color: "black" }}>
                Upload File
              </Typography>
              <label
                htmlFor="file-upload"
                style={{
                  cursor: "pointer",
                  color: "black",
                  border: "1px solid black",
                  borderRadius: "4px",
                  padding: "10px",
                  width: "100%",
                  boxSizing: "border-box",
                  minHeight: "100px", // Set your desired minimum height
                  backgroundColor: "lightgray", // Change background color to light gray
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Choose a File
              </label>
              <input
                onChange={handleFileChange}
                type="file"
                id="file-upload"
                style={{ display: "none" }}
              />
              {uploadFile && (
                <div style={{ color: "black" }}>{uploadFile.name}</div>
              )}
            </Stack>
          </Grid>

          <Grid container sx={{ padding: "0 0 20px 20px" }}>
            <Grid item xs={11} className="messageFooter">
              <Box className="response" onClick={handleCloseEdit}>
                Update
              </Box>
              <Box className="cancel" onClick={handleCloseEdit}>
                Cancel
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>

      <>
        <Stack
          direction="row"
          spacing={2}
          sx={{ margin: "10px", marginTop: "20px" }}
        >
          <Box
            onClick={handleClickOpen}
            sx={{
              backgroundColor: "#1d82f5",
              "&:hover": { backgroundColor: "orange" },
              textAlign: "center",
              padding: "10px",

              height: "40px",
              width: "110px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              borderRadius: "4px",
              fontSize: "15px",
              fontFamily: "sans-serif",
              cursor: "pointer",
            }}
          >
            <AddIcon />
            Add Client
          </Box>
        </Stack>
        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          PaperProps={{
            style: {
              position: "absolute",
              background: "#141b2d",
              top: "0",
              backgroundColor: "white",
              maxHeight: "95%",
              margin: "auto",
              overflowX: "hidden",
              zIndex: 1200,
            },
          }}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} component="div">
                Add New Client
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ background: "#fff" }}>
            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={4} sm={3}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Client Name
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => setName(e.target.value)}
                    placeholder="e.g.Name"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4} sm={3}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Email
                  </Typography>

                  <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    placeholder="eg@example.com"
                    type="email"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={8} sm={8} md={4}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Profile Picture
                  </Typography>

                  <label
                    htmlFor="file-upload"
                    style={{
                      cursor: "pointer",
                      color: "black",
                      border: "1px solid black",
                      borderRadius: "4px",
                      padding: "10px",
                      width: "100%",
                      boxSizing: "border-box",
                      minHeight: "55px", // Set your desired minimum height
                      backgroundColor: "#fff", // Change background color to light gray
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Choose a File
                  </label>
                  <input
                    onChange={handleFileChange}
                    type="file"
                    id="file-upload"
                    style={{ display: "none" }}
                  />
                  {uploadFile && (
                    <div style={{ color: "black" }}>{uploadFile.name}</div>
                  )}
                </Stack>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={8} sm={3}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Mobile No
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => setMobile(e.target.value)}
                    placeholder="e.g.+91 12345689"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={11} sm={2} md={1.5}>
                <Stack direction="column" spacing={2}>
                  <Typography>Gender</Typography>
                  <Select
                    value={gender}
                    autoFocus
                    fullWidth
                    onChange={(e) => setGender(e.target.value)}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    sx={{
                      border: "1px solid black",
                      boxSizing: "border-box",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black !important",
                        "&:hover fieldset": {
                          borderColor: "black !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black !important",
                        },
                      },
                      color: "black",
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>---</em>
                    </MenuItem>
                    {GenderValue.map((artical) => (
                      <MenuItem value={artical.id} key={artical.id}>
                        {artical.gender}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={8} sm={3} md={2.5}>
                <FormControl spacing={2} sx={{ paddingLeft: "20px" }}>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    sx={{
                      color: "black",
                      padding: "4px",
                      borderRadius: "4px",
                    }}
                  >
                    Login Allowed?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={isLogin}
                    onChange={handleChangeLogin}
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "#1d82f5" },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="2"
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "#1d82f5" },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={8} sm={3} md={2.5}>
                <FormControl spacing={2}>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    sx={{
                      color: "gray",
                      padding: "4px",
                      borderRadius: "4px",
                    }}
                  >
                    Receive email notifications?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={emailNot}
                    onChange={handleChangeEmail}
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "#1d82f5" },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="2"
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "#1d82f5" },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {/* <Grid item xs={10} sm={10}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Address
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="e.g.No:2468,Street, Nagar, City"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={10} sm={10}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    About
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => setAbout(e.target.value)}
                    placeholder="Type here..."
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid> */}

            <hr />
            <Box
              sx={{
                fontSize: "20px",
                fontWeight: 550,
                padding: "5px 30px",
                color: "#333",
              }}
            >
              Company Details
            </Box>
            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={11} sm={2.7}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Company Name
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder="e.g. Acme Corporation"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={11} sm={2.7}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Company Email
                  </Typography>

                  <TextField
                    onChange={(e) => setCompanyEmail(e.target.value)}
                    fullWidth
                    placeholder="eg@example.com"
                    type="email"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={11} sm={2.7}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Official Website
                  </Typography>

                  <TextField
                    onChange={(e) => setWebsite(e.target.value)}
                    fullWidth
                    placeholder="eg@example.com"
                    type="email"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={11} sm={2.7}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Tax Name
                  </Typography>

                  <TextField
                    onChange={(e) => setTaxName(e.target.value)}
                    fullWidth
                    placeholder="e.g. GST/VAT"
                    type="email"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={11} sm={2.7}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    GST/VAT Number
                  </Typography>

                  <TextField
                    fullWidth
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="e.g.1820XXXXXXXXX"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={11} sm={2.7}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Office Phone Number
                  </Typography>

                  <TextField
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    fullWidth
                    placeholder="e.g.98XXXXXXXX"
                    type="text"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={8} sm={4} md={4}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Industry Type
                </Typography>

                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                      <Select
                        labelId="designation-select-label"
                        id="designation-select"
                        value={industryType}
                        onChange={(e) => setIndustyType(e.target.value)}
                        displayEmpty
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleAddClickDesig}
                              edge="end"
                              sx={{
                                color: "#000",
                                backgroundColor: "#fff",
                                "&:hover": {
                                  backgroundColor: "#fff",
                                  color: "#000",
                                },
                              }}
                            >
                              Add
                            </IconButton>
                          </InputAdornment>
                        }
                        sx={{
                          border: "1px solid black",
                          boxSizing: "border-box",
                          "& .MuiOutlinedInput-root": {
                            borderColor: "black !important",
                            "&:hover fieldset": {
                              borderColor: "black !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black !important",
                            },
                          },
                          color: "black",
                        }}
                      >
                        <MenuItem disabled value="">
                          <em>---</em>
                        </MenuItem>
                        {industryTypeData.map((item) => (
                          <MenuItem key={item.id} value={`${item.id}`}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>

                      {/* Dialog for adding new department */}
                      <Dialog
                        fullWidth
                        maxWidth="md"
                        open={openDialogDesig}
                        onClose={handleDialogCloseDesig}
                        TransitionComponent={Transition}
                        PaperProps={{
                          style: {
                            position: "absolute",
                            background: "#141b2d",
                            top: "0",
                            backgroundColor: "white",
                            maxHeight: "95%",
                            margin: "auto",
                            overflowX: "hidden",
                            zIndex: 1200,
                          },
                        }}
                      >
                        <Box sx={{ background: "#fff", color: "#000" }}>
                          <DialogTitle>
                            <span
                              style={{
                                background: "blue",
                                color: "#fff",
                                padding: "15px",
                                borderRadius: "5px",
                              }}
                            >
                              Add Industry Type
                            </span>
                          </DialogTitle>
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              marginTop: "10px",
                              padding: { xs: "10px", sm: "20px" },
                            }}
                          >
                            <Grid item xs={12} sm={6} md={5.5}>
                              <Stack direction="column" spacing={2}>
                                <Typography>Name</Typography>
                                <TextField
                                  autoFocus
                                  margin="dense"
                                  placeholder="Designation Name"
                                  type="text"
                                  fullWidth
                                  value={industryName}
                                  onChange={(e) =>
                                    setIndustryName(e.target.value)
                                  }
                                  inputProps={{ style: { color: "black" } }}
                                  InputLabelProps={{
                                    style: { color: "black" },
                                  }}
                                  sx={{
                                    border: "1px solid black",
                                    borderRadius: "5px",
                                    "& .MuiOutlinedInput-root": {
                                      borderColor: "black",
                                      "&:hover fieldset": {
                                        borderColor: "black",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "black",
                                      },
                                    },
                                    "& .MuiOutlinedInput-input": {
                                      "&::placeholder": { color: "gray" },
                                    },
                                  }}
                                />
                              </Stack>
                            </Grid>

                            <Grid item xs={11.5} sx={{ textAlign: "start" }}>
                              <DialogActions>
                                <Button
                                  onClick={handleDialogCloseDesig}
                                  color="primary"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={AddIndustry}
                                  color="primary"
                                  sx={{
                                    background: "blue",
                                    color: "#fff",
                                    "&:hover": {
                                      background: "#000",
                                    },
                                  }}
                                >
                                  Add
                                </Button>
                              </DialogActions>
                            </Grid>
                          </Grid>
                        </Box>
                      </Dialog>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={11} sm={2.7}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    State
                  </Typography>

                  <TextField
                    onChange={(e) => setState(e.target.value)}
                    fullWidth
                    placeholder="e.g. Uttar Pradesh"
                    type="text"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={11} sm={2.7}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Postal code
                  </Typography>

                  <TextField
                    onChange={(e) => setPostalCode(e.target.value)}
                    fullWidth
                    placeholder="e.g. 902356"
                    type="text"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ color: "black", margin: "10px" }}
            >
              <Grid item xs={11} sm={5.7}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Billing Address
                  </Typography>

                  <TextField
                    onChange={(e) => setBilling(e.target.value)}
                    fullWidth
                    placeholder="e.g. gomti nagar lucknow"
                    type="text"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={11} sm={5.7}>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="p"
                    gutterBottom
                    style={{ color: "black" }}
                  >
                    Shipping Address
                  </Typography>

                  <TextField
                    onChange={(e) => setShipping(e.target.value)}
                    fullWidth
                    placeholder="e.g. gomti nagar lucknow"
                    type="text"
                    inputProps={{ style: { color: "black" } }}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{
                      border: "1px solid black",
                      borderRadius: "5px",
                      "& .MuiOutlinedInput-root": {
                        borderColor: "black",
                        "&:hover fieldset": {
                          borderColor: "black",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "black",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        "&::placeholder": { color: "gray" },
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ padding: "20px", marginBottom: "40px", marign: "10px" }}
            >
              <Stack direction="column" sx={{ width: "100%" }} spacing={2}>
                <Typography variant="p" gutterBottom style={{ color: "black" }}>
                  Description<span style={{ color: "red" }}>*</span>
                </Typography>

                <ReactQuill
                  value={description}
                  onChange={setDescription}
                  modules={modules}
                  theme="snow"
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    color: "black",
                    borderBlockColor: "black",
                  }}
                />
              </Stack>
            </Grid>
            <Grid container sx={{ padding: "0 0 20px 20px" }}>
              <Grid item xs={11} className="messageFooter">
                <Box className="response" onClick={SaveData}>
                  Save
                </Box>
                <Box className="cancel" onClick={handleCloseEdit}>
                  Cancel
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Dialog>

        <Divider />
        <ListItemText style={{ background: "white", margin: 0 }}>
          <ThemeProvider theme={getTheme()}>
            <Box
              sx={{
                maxWidth: "100%",
              }}
            >
              <MUIDataTable
                title={
                  <span
                    style={{
                      fontWeight: 700,
                      fontSize: "1.5rem",
                      color: "#333",
                    }}
                  >
                    Clients Lists
                  </span>
                }
                data={articalData}
                columns={columns}
                options={options}
              />
            </Box>
          </ThemeProvider>
        </ListItemText>
      </>
    </>
  );
}

export default Clients;
